<!--Pagina inicio de la Experiencia (subir foto)-->
<template>
    <v-container fill-height fluid class="black d-flex align-stretch justify-center">
        <v-row class=""  justify="center" no-gutters>
            <!-- Inicio del menu -->
            <v-col cols="12" md="11">
                <menus/>
            </v-col>
            <v-col cols="12" md="11" class="py-0 px-0 fondoFoto " >
                <!--Inicio de la Tarjeta invisible con el contenido al fondo -->
                <v-responsive :aspect-ratio="9/16" align="center" max-width="400" height="100%" class="justify-center  text-center mx-auto align-end">
                    <v-card-text class="white--text inclinado"> 
                        ESTÁS A UN PASO DE MOSTRARLE AL MUNDO TU VERDADERO YO
                    </v-card-text>
                    <v-card-text class="becks--text mt-n8 inclinado">
                        SHOW YOUR MAGIC
                    </v-card-text>
                    <v-btn color="becks"  elevation="2" class="ma-5" max-width="" rounded @click="showFileChooser">
                        &nbsp;&nbsp;&nbsp;SUBIR FOTO&nbsp;&nbsp;&nbsp;
                    </v-btn>
                    <input
                        id="archivos"
                        ref="input"
                        type="file"
                        name="image"
                        accept="image/png, image/jpeg"
                        @change="setImage"
                    />
                    <v-btn color="becks" small outlined elevation="2" class="mb-5" max-width="" rounded @click="cambiarPagina('Participaciones')">
                        &nbsp;&nbsp;VER MI PERFIL&nbsp;&nbsp;
                    </v-btn>
                </v-responsive>
            </v-col>
        </v-row>
        <!-- Animacion inferior de los hashtag -->
        <v-row class="justify-center">
            <v-col cols="12" md="11">
                <div class="img1">&nbsp;</div>
            </v-col>
        </v-row>
        <!-- Inicio del overlay de carga -->
        <v-overlay :value="overlay">
            <v-progress-circular
                indeterminate
                color="becks"
                size="64"
            ></v-progress-circular>
        </v-overlay>
        <!-- Inicio del Modal del recorte de imagen -->
        <v-dialog
            v-model="dialog"
            persistent
            width="80%"
            max-width="600px"
            transition="dialog-transition"
        >
            <v-responsive
                width="100%"
                class="black text-center"
            >
                <!-- Componente del recorte -->
                <vue-cropper
                    ref="cropper"
                    :aspect-ratio="this.tipoDispositivo == 2? 16 / 9: 9/16"
                    :src="imgSrc"
                    preview=".preview"
                    class="mx-3 my-3"
                    max-height="500px"
                    :minCropBoxWidth="this.tipoDispositivo == 2? 960: 540"
                    :background="false"
                    :can-scale="false"
                    :can-move="false"
                    
                />
                <!--Botones de acciones de la ventana del recorte-->
                <div class="actions mx-3 d-none d-md-flex">
                    <v-btn icon dark large @click.prevent="zoom(0.2)" color="becks">
                        <v-icon x-large>mdi-magnify-plus</v-icon>
                    </v-btn>
                    <v-btn icon dark large @click.prevent="zoom(-0.2)" color="becks">
                        <v-icon x-large>mdi-magnify-minus</v-icon>
                    </v-btn>
                    <v-btn icon dark large @click.prevent="rotate(-90)" color="becks">
                        <v-icon x-large>mdi-rotate-left</v-icon>
                    </v-btn>
                    <v-btn icon dark large @click.prevent="rotate(90)" color="becks">
                        <v-icon x-large>mdi-rotate-right</v-icon>
                    </v-btn>
                </div>
                <v-row class="mx-2 d-none d-md-flex">
                    <v-col cols="12" md="6">
                    <v-btn  dark large @click.prevent="reset" class="d-inline-block btn-becks" block>
                        Reiniciar
                    </v-btn>
                    </v-col>
                    <v-col cols="12" md="6">
                    <v-btn  dark large @click.prevent="showFileChooser" class="d-inline-block btn-becks" block>
                        Cambiar Imagen
                    </v-btn>
                    </v-col>
                    <v-col cols="12">
                    <v-btn large @click="UploadNuevo()" class="d-inline-block becks btn-becks" block>
                        Continuar
                    </v-btn>
                    </v-col>
                </v-row>
                <!--Tarjeta emergente para mobile-->
                <v-bottom-sheet v-model="mostrarOpciones" width="90%" color="rgba(0,0,0,0)" class="elevation-0 d-md-none">
                    <div class="text-center">
                        <v-btn class="ml-n12 mt-n16 becks" @click="mostrarOpciones= !mostrarOpciones" style="position: fixed" min-width="100px" max-width="100px" v-show="mostrarOpciones"><v-icon>mdi-arrow-down</v-icon></v-btn>
                    </div>
                    <div class="actions mx-3">
                        <v-btn icon dark large @click.prevent="zoom(0.2)" color="becks">
                            <v-icon x-large>mdi-magnify-plus</v-icon>
                        </v-btn>
                        <v-btn icon dark large @click.prevent="zoom(-0.2)" color="becks">
                            <v-icon x-large>mdi-magnify-minus</v-icon>
                        </v-btn>
                        <v-btn icon dark large @click.prevent="rotate(-90)" color="becks">
                            <v-icon x-large>mdi-rotate-left</v-icon>
                        </v-btn>
                        <v-btn icon dark large @click.prevent="rotate(90)" color="becks">
                            <v-icon x-large>mdi-rotate-right</v-icon>
                        </v-btn>
                    </div>
                    <v-row class="mx-2">
                        <v-col cols="12" md="6">
                        <v-btn  dark large @click.prevent="reset" class="d-inline-block btn-becks" block>
                            Reiniciar
                        </v-btn>
                        </v-col>
                        <v-col cols="12" md="6">
                        <v-btn  dark large @click.prevent="showFileChooser" class="d-inline-block btn-becks" block>
                            Cambiar Imagen
                        </v-btn>
                        </v-col>
                        <v-col cols="12">
                        <v-btn large @click="UploadNuevo()" class="d-inline-block becks btn-becks" block>
                            Continuar
                        </v-btn>
                        </v-col>
                    </v-row>
                </v-bottom-sheet>
                
            </v-responsive>
            
        </v-dialog>
        <v-btn class="becks d-md-none" @click="mostrarOpciones= !mostrarOpciones" fixed style="z-index: 1000; bottom: 0;" min-width="100px" v-show="!mostrarOpciones && dialog"><v-icon>mdi-arrow-up</v-icon></v-btn>
    </v-container>
</template>

<script>
    import menus from '../components/menus.vue'
    import axios from 'axios'
    import VueCropper from 'vue-cropperjs';
    import 'cropperjs/dist/cropper.css';

    export default {
        data: () => ({
            mostrarOpciones: false,
            dialog: false,
            overlay: false,
            tipoDispositivo: "",
            //data de la libreria
            imgSrc: 'https://www.eluniversal.com.mx/sites/default/files/2020/03/19/como_extraer_un_texto_de_una_imagen.jpg',
            cropImg: '',
            data: null,
            controlZoom: 0
        }),
        methods: {
            doClick() {
                var el = document.getElementById("archivos")
                if (el) {
                    el.click();
                }
            },
            async UploadNuevo() { 
                //TODO: aqui se sube la imagen     
                    this.dialog = !this.dialog
                    this.overlay = !this.overlay
                    var ancho = this.tipoDispositivo == 2 ? 1920:1080
                    var alto = this.tipoDispositivo == 2 ? 1080:1920
                    // console.log('valor del ancho: '+ ancho +'\nvalor del alto: '+alto)

                    //agregamos las opciones para que nos genere siempre imagenes de 1080 x 1920 y sacamos el base 64
                    var archivo = this.$refs.cropper.cropper.getCroppedCanvas({
                                    width: ancho,
                                    height: alto,
                                    minWidth: ancho,
                                    minHeight: alto,
                                    maxWidth: ancho,
                                    maxHeight: alto,
                                    fillColor: 'transparent',
                                    imageSmoothingEnabled: true,
                                    imageSmoothingQuality: 'low',
                                }).toDataURL('image/jpeg');

                    //Creamos el formulario con formData y hacemos la peticion axios a subirImagen.php
                    let formData = new FormData();
                    formData.append('archivo', archivo)
                    formData.append('tipoDispositivo', this.tipoDispositivo)
                    await axios.post(
                        "imagen/subirImagen.php"
                        ,formData
                    )
                    .then(response => {
                        if(response.data.error == 0){
                            this.overlay = !this.overlay //Quita el overlay si todo ocurre normalmente
                            sessionStorage.nombreImagen = response.data.nombreImagen
                            sessionStorage.tipoArchivo = response.data.tipoArchivo
                            this.imgname = response.data.name
                            this.cambiarPagina('Foto')
                        }else{
                            // console.log("error: " + response.data.msg);
                            this.overlay = !this.overlay //Quita el overlay si ocurre un error
                        }
                    })
                    .catch(e => {
                        // console.log('Error > ', e)
                    })
            },
            cambiarPagina(lugar){// metodo para cambiar de pagina
                axios.get('/').then(response => (
                    this.$router.push({name: lugar})
                ))
                .catch(
                    // error => console.log('Hubo un error: ' + error)
                )
            },
            checkCookie(){
                axios
                .post("check_cookie.php", {
                
                })
                .then(function (response) {
                    // console.log(response.data.message)

                    if(response.data.message != 'galleta'){
                        window.location.replace("/Edad");
                    }

                })
                .catch((error) => {
                    // console.log(error)
                });
            },
            //metodos de la libreria
            reset() {
            this.$refs.cropper.reset();
            },
            rotate(deg) {
            this.$refs.cropper.rotate(deg);
            },
            setImage(e) {
            const file = e.target.files[0];

            if (file.type.indexOf('image/') === -1) {
                alert('Please select an image file');
                return;
            }

            if (typeof FileReader === 'function') {
                const reader = new FileReader();

                reader.onload = (event) => {
                this.imgSrc = event.target.result;
                // rebuild cropperjs with the updated source
                this.$refs.cropper.replace(event.target.result);
                };

                reader.readAsDataURL(file);
                this.dialog = true;
            } else {
                alert('Sorry, FileReader API not supported');
            }
            },
            async showFileChooser() {
                await this.$refs.input.click();
                this.mostrarOpciones= false
            },
            zoom(percent) {
                if(this.controlZoom> -1 && this.controlZoom< 1){
                    this.$refs.cropper.relativeZoom(percent)
                    this.controlZoom = this.controlZoom + percent
                }else{
                    if(Math.abs(this.controlZoom + percent) <= 1){
                        this.controlZoom = this.controlZoom + percent
                    }
                }
                
            },
            async queryUser(){
                await axios.post("query_user.php", {

                }).then(function (response) {
                    // console.log(response.data.validacion)
                    if(response.data.validacion != 'existente'){
                        window.location.replace("/Edad");
                    }
                }).catch(
                    // error => console.log(error)
                )
            },
        },
        computed:{

        },
        created(){
            this.checkCookie();
            //funciones para saber si es desktop o mobile
            const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
            this.tipoDispositivo = width > 960 ? '2' : '1'//si es desktop = 2 y mobile = 1
            this.queryUser();
        },
        name: 'AgregarUnaFoto',
        components: {
            menus,
            VueCropper
        },
    }
</script>

<style scoped>
    .btn-becks{
        border-radius: 50px;
    }

    .fondoFoto{
        background-image: url("../assets/AgregarUnaFoto.jpg");
        background-position: center;
        background-size: cover;
    }

    .inclinado{
        transform: rotate(-5deg);
    }
    .img1{
        background-repeat: repeat-x;
        height: 100%;
        animation: 25s infinite linear MOVE-BG-BOT;
        background-image: url('/text_arriba2.png');
        background-size: auto 30px;
    }

    @keyframes MOVE-BG-BOT {
        from {
            background-position: 100% 0%;
        }
        to { 
            
            background-position: 0% 0%;
        }
    }

    input[type="file"] {
        display: none;
    }

    .actions {
        margin-top: 1rem;
        display: flex;
        justify-content: space-between;
    }
</style>
